import { TypedUseSelectorHook, useDispatch, useSelector, useStore } from 'react-redux';

import partial from 'lodash/partial';

import globalStore from '@/legacy/store';
import type { AsyncDispatch, RootState } from '@/legacy/store/types';
import whenState from '@/legacy/store/utils/whenState';

import { WhenStateFunction } from './types';

const useAppDispatch = () => useDispatch<AsyncDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/**
 * Hook that provides a `whenState` function. `whenState()` returns a promise that resolves only
 * when the `condition` becomes true for the selected state. This allows async code to wait for a
 * certain state before proceeding.
 *
 * @see {@link WhenStateFunction}
 */
function useWhenState(): WhenStateFunction {
  const store = useStore() as typeof globalStore;
  return partial(whenState, store);
}

export { useAppDispatch, useAppSelector, useWhenState };
